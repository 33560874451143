/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import Airtable from 'airtable';
import axios from 'axios';

const baseAccessToken = `${process.env.AIRTABLE_NEWSLETTER_BASE_ACCESS_TOKEN}`;
const baseId = `${process.env.AIRTABLE_BASE_ID}`;
const ipGeolocation = process.env.IP_GEOLOCATION;

const base = new Airtable({ apiKey: baseAccessToken }).base(baseId);

interface FormValues {
  email: string;
}

interface Props {
  center: boolean;
  form1: boolean;
  // eslint-disable-next-line react/require-default-props
  onClose?: () => void;
  subscribed?: string;
  setSubscribed?: (value) => void;
}

export default function Subscribe({
  center,
  form1,
  onClose,
  subscribed,
  setSubscribed,
}: Props) {
  const [formValues, setFormValues] = useState<FormValues>({ email: `` });
  const [subscribing, setSubscribing] = useState(false);
  const [errorInfo, setErrorInfo] = useState<string | null>(null);

  // eslint-disable-next-line consistent-return
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${ipGeolocation}`,
      );
      return response.data;
    } catch (error) {
      // console.error(`Error fetching IP geolocation:`, error);
      setErrorInfo(`Browser geolocation is not accessible.`);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubscribing(true);
    try {
      if (subscribed !== formValues.email) {
        // Get user's geolocation
        const { state_prov, city, country_name } = await fetchData();
        // console.log(geolocationResult);

        // Send form values to Airtable
        await base(`Newsletter-Subscribers`).create({
          Email: formValues.email,
          City: city,
          State_Province: state_prov,
          Country: country_name,
        });

        // Clear form
        setFormValues({ email: `` });
        if (subscribed === `null`) {
          setSubscribed(formValues.email);
          setSubscribing(false);
          onClose();
        }
        return;
      }
      setSubscribing(false);
      return;
    } catch (error) {
      setSubscribing(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // Update form state with new input value
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleClick = () => {
    setSubscribing(true);
    setTimeout(() => setSubscribing(false), 1000); // simulate async operation
  };

  return (
    <div>
      {/* <h3 className="mt-10 text-lg font-medium text-black pb-[19px]">
        Subscribe to our newsletter
      </h3> */}
      {form1 ? (
        <form
          onSubmit={handleSubmit}
          className="flex flex-col"
          // className={`${!center && `flex flex-row`} flex flex-col`}
        >
          <div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Your email address"
              value={formValues.email}
              onChange={handleInputChange}
              required
              className="xl:w-[20vw] w-full bg-[#FCFCFD] border-[0.908284px] border-solid border-[#CFD0D7]"
            />
          </div>
          <div className={`${center && `mx-auto`} w-fit`}>
            {errorInfo && (
              <p className="mt-1 text-[14px] text-red-600">{errorInfo}</p>
            )}
            <button
              type="submit"
              onClick={handleClick}
              className="mt-[15.85px] rounded-[3.63314px] bg-primary text-[#FFFFFF] w-[100.58px] h-[auto] py-[7px] px-[10px] text-center text-sm font-medium hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
            >
              {subscribing ? `Subscribing...` : `Subscribe`}
            </button>
          </div>
        </form>
      ) : (
        <form
          action=""
          onSubmit={handleSubmit}
          className="md:bg-white rounded-sm flex flex-col md:flex-row justify-center md:justify-between items-center h-[52px] py-[10px] px-[14px]"
        >
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Your email address"
            value={formValues.email}
            onChange={handleInputChange}
            required
            className="xl:w-[20vw] w-[265px] xs:w-[335px] md:w-[60%] bg-[#FCFCFD] border-[0.908284px] border-solid border-[#CFD0D7] rounded-[3.633px]"
          />
          {errorInfo && (
            <p className="mt-1 md:mt-0 px-1 text-[14px] text-red-600">
              {errorInfo}
            </p>
          )}
          <button
            type="submit"
            onClick={handleClick}
            className="rounded-[3.63314px] bg-primary text-[#FFFFFF] w-[265px] xs:w-[335px] md:w-[100.58px] h-[auto] mt-5 md:mt-0 py-[7px] px-[10px] text-center text-sm font-medium hover:shadow-2xl hover:scale-[1.015] hover:bg-navy-blue transition-all duration-[0.1s] ease-in-out"
          >
            {subscribing ? `Subscribing...` : `Subscribe`}
          </button>
        </form>
      )}
    </div>
  );
}
